@import '../core/variables/_variables.scss';

.status-button-hover {
  &:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-box-shadow: 0 8px 25px -8px #aaa;
    box-shadow: 0 8px 25px -8px #aaa;
    cursor: pointer;
  }
}

.location-status-pulse-online {
  box-shadow: 0 0 0 0 rgba(94, 213, 148, 1);
  transform: scale(1);
  animation: pulse-state-online 2s infinite;
}

.location-status-pulse-offline {
  box-shadow: 0 0 0 0 rgba(227, 26, 22, 1);
  transform: scale(1);
  animation: pulse-state-offline 2s infinite;
}

@keyframes pulse-state-online {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(94, 213, 148, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(94, 213, 148, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(94, 213, 148, 0);
  }
}

@keyframes pulse-state-offline {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(227, 26, 22, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(94, 213, 148, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(94, 213, 148, 0);
  }
}

.locker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}

.drawer-outer-wrapper {
  width: 100%;
  background-color: $gray-200;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;

  &.drawer-outer-wrapper-expanded {
    background-color: #fff;
  }
}

.drawer-top-wrapper {
  height: 120px;
  width: 100%;
  border: 1px solid $gray-200;
  border-bottom: none;
  padding: 0px;
  border-radius: 10px 10px 0 0;
  display: flex;
}

.drawer-info {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-radius: 0 10px 10px 0;
  background-color: #fff;
  border-bottom: 1px solid $gray-200;
}

.drawer-info-content {
  height: 100%;
}

.drawer-info-row {
  height: 50%;
}

.drawer-info-containers {
  border-right: 1px solid $gray-200;
}

.drawer-expander {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-200;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
}

.drawer-expander:hover {
  color: $primary !important;
}

.drawer-expand-text {
  color: inherit !important;
}

.drawer-expandable-wrapper {
  width: 100%;
  border: 1px solid $gray-200;
  border-top: none;
  border-bottom: none;
  background-color: #fff;
  overflow: hidden;
}

.locker {
  height: 80px;
  width: 80px;
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  color: white;
  overflow: hidden;

  &.owned-by-current-user {
    background: #da23ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#da23ff), to(#9733ee));
    background: linear-gradient(to bottom, #da23ff 0%, #9733ee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#da23ff', endColorstr='#9733ee', GradientType=0);
  }

  &.free {
    background: linear-gradient(178deg, #0ed392 0%, #1fb3c2 83%, #23accd 100%);
  }

  &.open-border {
    border: 4px solid #007cff;
  }

  &.expired {
    background: -webkit-gradient(linear, left bottom, left top, from(#6525a1), to(#411869));
    background: linear-gradient(to top, #6525a1 0%, #411869 100%);
  }

  &.easyToUse {
    background: -webkit-gradient(linear, left bottom, left top, from(#02bcd1), to(#03CFE7));
    background: linear-gradient(to top, #02bcd1 0%, #03CFE7 100%);
  }

  &.easyToUse-border {
    background: linear-gradient(178deg, #0ed392 0%, #1fb3c2 83%, #23accd 100%);
    border: 2px solid #007cff;
  }

  &.occupied {
    background: -webkit-gradient(linear, left bottom, left top, from(#ff8f5c), to(#fd695b));
    background: linear-gradient(to top, #ff8f5c 0%, #fd695b 100%);
  }

  &.pending {
    background: -webkit-gradient(linear, left bottom, left top, from(#09ff00), to(#91158d));
    background: linear-gradient(to top, #09ff00 0%, #91158d 100%);
  }

  &.open {
    background: -webkit-gradient(linear, left top, left bottom, from(black), color-stop(0%, black), color-stop(0%, cyan), color-stop(0%, #00baff), color-stop(100%, #007cff), to(black));
    background: linear-gradient(180deg, black 0%, black 0%, cyan 0%, #00baff 0%, #007cff 100%, black 100%);
  }

  &.offline {
    background: #da23ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#757575), to(#878787));
    background: linear-gradient(to bottom, #757575 0%, #878787 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#757575', endColorstr='#878787', GradientType=0);
  }

  &.reserved {
    background: #da23ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#da23ff), to(#9733ee));
    background: linear-gradient(to bottom, #da23ff 0%, #9733ee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#da23ff', endColorstr='#9733ee', GradientType=0);
  }

  &.alarm {
    background: #e52d27;
    background: -webkit-gradient(linear, left top, left bottom, from(#e52d27), to(#b31217));
    background: linear-gradient(to bottom, #e52d27 0%, #b31217 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e52d27', endColorstr='#b31217', GradientType=0);
    background-color: #f71735;
    background-image: linear-gradient(147deg, #f71735 0%, #db3445 74%);
  }

  &.desactive {
    background: #232526;
    background: -webkit-gradient(linear, left top, left bottom, from(#232526), to(#232526));
    background: linear-gradient(to bottom, #232526 0%, #232526 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#232526', endColorstr='#232526', GradientType=0);
    background-color: #232526;
    background-image: linear-gradient(147deg, #232526 0%, #232526 100%);
  }

  &.placeholder {
    pointer-events: none;
    opacity: 0.2;
    background: #232526;
    background: -webkit-gradient(linear, left top, left bottom, from(#232526), to(#232526));
    background: linear-gradient(to bottom, #232526 0%, #232526 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#232526', endColorstr='#232526', GradientType=0);
    background-color: #232526;
    background-image: linear-gradient(147deg, #232526 0%, #232526 100%);
  }

  &.cleaning {
    background: #FFE27A;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFE27A), to(#E8D964));
    background: linear-gradient(to bottom, #FFE27A 0%, #E8D964 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFE27A', endColorstr='#E8D964', GradientType=0);
    background-color: #E8D964;
    background-image: linear-gradient(147deg, #FFE27A 0%, #E8D964 100%);
  }

  &.total {
    background: #7e67ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#7e67ff), to(#4f32f7));
    background: linear-gradient(to bottom, #7e67ff 0%, #4f32f7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7e67ff', endColorstr='#4f32f7', GradientType=0);
    background-color: #4f32f7;
    background-image: linear-gradient(147deg, #7e67ff 0%, #4f32f7 100%);
  }

  &.neutral {
    background-color: transparent;
    border: 1px solid #232526;
    color: #232526;
  }

  &.wide {
    width: auto;
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
  }

  &.log {
    width: 45px;
    height: 45px;
    left: -5.3rem !important;
    border-radius: 5px !important;
    background-color: #fff;
    margin-top: 0px;
    pointer-events: none;

    img {
      width: 100%;
    }

    svg {
      width: 20px;
    }

    span {
      position: absolute;
      font-size: 0.5em;
      font-weight: bold;
      top: 2px;
    }
  }

  &.alert {
    width: 45px;
    height: 45px;
    border-radius: 5px !important;

    svg {
      width: 25px;
    }

    span {
      position: absolute;
      font-size: 0.5em;
      font-weight: bold;
      top: 2px;
    }
  }

  &:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-box-shadow: 0 8px 25px -8px #aaa;
    box-shadow: 0 8px 25px -8px #aaa;
  }

  span {
    position: absolute;
    font-size: 1em;
    font-weight: bold;
    top: 5px;
  }

  span.number {
    right: 5px;
  }

  span.size {
    left: 5px;
  }

  &.drawer {
    border-radius: 10px 0 0 10px;
    margin: 0px;
    height: 100%;
    width: 120px;

    span {
      text-align: center;
      font-size: 2.5rem;
      position: relative;
      width: 100%;
      right: 0;
    }

    .locker-icon {
      position: absolute;
      width: 15px;
      top: 10%;
      left: 10%;
    }
  }
}

.open-occupied-sign {
  position: absolute;
  bottom: 5px;
  background: -webkit-gradient(linear, left bottom, left top, from(#ff8f5c), to(#fd695b));
  background: linear-gradient(to top, #ff8f5c 0%, #fd695b 100%);
  width: 60%;
  height: 8px;
  border-radius: 3px;
}

.locker-time-chip {
  border-radius: 0px;
  font-size: 0.7em;
  padding: 3px;
  color: $white !important;

  &.green {
    background-color: $green;
  }

  &.orange {
    background-color: #fd695b;
  }

  &.red {
    background-color: #b31217;
  }
}

.order-deposited-since {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  margin: 0;
  line-height: 1em;
  border-top: 1px solid $white;
}

@media only screen and (max-width: 860px) {
  .locker-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    overflow: scroll;
    max-height: 400px;
  }

  .drawer-info-row {
    max-height: 25%;
    justify-content: left !important;
    margin-left: 5%;
  }

  .drawer-top-wrapper {
    height: 100px;
  }

  .drawer-info-containers {
    border: none;
  }
}